import * as React from 'react'
import Icon from "../Icon/Icon"

import './PageIntro.sass'

class PageIntro extends React.Component<{
  title: string,
  description: string,
  heading?: string,
}> {

  render() {
    const {title, description, heading} = this.props;
    return (
      <section className='page-intro'>
        <div className='max-width'>
          <h2 className='page-intro__title' dangerouslySetInnerHTML={{__html: title}}/>
          <div className='page-intro__text' dangerouslySetInnerHTML={{__html: description}}/>
          {!!heading && (
            <Icon name={heading} className='heading' raw/>
          )}
        </div>
      </section>
    )
  }
}

export default PageIntro
