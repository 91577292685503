import * as React from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'
import Lottie from 'react-lottie'
import Icon from "../Icon/Icon"

import './ProjectCard.sass'

type ProjectCardDataType = {
  className?: string,
  tags?: string,
  title: string,
  subtitle?: string,
  link?: string,
  isHome?: boolean,
  name: string,
  icon: string,
  content?: any,
  lottie?: LottieDataType,
}

type LottieDataType = {
  width?: string,
  height?: string,
  options: any
}

type Props = {
  data: ProjectCardDataType
  isImageFirst?: boolean,
}

class ProjectCard extends React.Component<Props> {

  render() {
    const { className, title, subtitle, isHome, link, tags, name, icon, lottie, content } = this.props.data;

    const lottieOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    const CustomLink = link ? Link : 'span';

    return (
      <div className={cn('project-card', className, {
        '-home': isHome,
        '-image-first': this.props.isImageFirst,
      })}>
        <div className='project-card__info'>
          <div className='project-card__tags'>{tags}</div>
          <h4 className='project-card__title'><CustomLink to={link}>{title}</CustomLink></h4>
          <div className='project-card__subtitle'>{subtitle}</div>
          <Link to={link} className={cn('project-card__link link', {'-disabled' : !link})}>{!!link ? 'Explore More' : 'Coming soon'}</Link>
        </div>
        <CustomLink to={link} aria-label={`Link to project: ${name}`} className={cn('project-card__image', `-${name}`)}>
          <Icon name={icon} raw />
          {!!lottie && (
            <div className='project-card__image-content'>
              <Lottie options={{...lottieOptions, animationData: lottie.options}}
                      height={lottie.height || '100%'}
                      width={lottie.width || '100%'}/>
            </div>
          )}
          {!!content && (
            <div dangerouslySetInnerHTML={{__html: content}}/>
          )}
        </CustomLink>
      </div>
    )
  }
}

export default ProjectCard
