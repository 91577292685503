import React, { Component } from 'react';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import SEO from '../components/seo';
import ProjectsPage from '../components/Pages/ProjectsPage/ProjectsPage';

class Projects extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='Projects'
          description='We believe meaningful work is defined by collaborating with our clients to not only create ideas that drive market share, but create work that rewards, captivates and ultimately — matters to people.'
        />
        <ProjectsPage/>
      </PageWrapper>
    );
  }
}

export default Projects;
