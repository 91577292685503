import * as React from 'react';
import { PROJECTS } from '../../../constants/constants';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import PageIntro from '../../UI/PageIntro/PageIntro';
import ProjectCard from '../../UI/ProjectCard/ProjectCard';

import './ProjectsPage.sass';

class ProjectsPage extends React.Component {

  render() {

    return (
      <main className='project-page'>
        <Header/>
        <PageIntro
          heading='heading-works'
          title="Our <span class='underlined'>Projects</span>: <br />Case Studies"
          description='We believe meaningful work is defined by collaborating with our clients to not only create ideas that drive market share, but create work that rewards, captivates and ultimately — matters to people. <br /><br />
                                We create stylish and effective web products and mobile applications based on business research and CA needs. '
        />
        <div className='project-page__content'>
          <div className='max-width'>
            {PROJECTS.map((item, i) => <ProjectCard data={item} key={i} isImageFirst={!!(i % 2)}/>)}
          </div>
        </div>
        <Footer/>
      </main>
    );
  }
}

export default ProjectsPage;
